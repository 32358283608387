import BaseModel from '@/libs/base/dataModel/BaseModel';

export class TemplateBlockListModel extends BaseModel {
  constructor () {
    super();
    this.name = ''; // 小区名称
    this.id = ''; // 小区编码
    this.communityCode = ''; // 小区编码
    this.provinceName = ''; // 省名称
    this.cityName = ''; // 市名称
    this.districtName = ''; // 区名称
    this.address = ''; // 具体地址
    this.deviceCount = ''; // 充电桩总数
    this.officeName = ''; // 办事处名称
    this.orgName = ''; // 区域公司名称
    this._checkId = '';
  }

  initData (resData) {
    super.initData(resData);
    this._checkId = this.id;
    return this;
  }

  static checkModelIsSelect (dataList, selectList) {
    const len = dataList.length;
    for (let i = 0; i < len; i++) {
      const model = dataList[i];
      model._checked = !!selectList.find(v => v._checkId === model._checkId);
    }
  }
}
