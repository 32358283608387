<!--
    Created by 程雨喵'mac on 2024/9/2.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    功能概述：选择下发小区范围
-->
<style lang="less">
.operate-block-choose {
  .block-setting-area {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .setting-left {
      white-space: nowrap;
      font-size: 12px;
      line-height: 12px;
      color: @textColorLight;
      .text-is-strong {
        color: @adornColor;
        font-size: 18px;
        font-weight: 600;
        line-height: 16px;
      }
    }
    .setting-right {
      font-size: 12px;
      position: relative;
      &:hover {
        .export-hover-show {
          opacity: 1;
          right: 0;
          width: auto;
          height: auto;
          transform: translateY(10px);
        }
      }
      @keyframes amountAnim1 {
        0% { top: -30px; transform: scale(0); opacity: 1 }
        50% { top: -30px; transform: scale(1.3); opacity: 1 }
        100% { top: -30px; transform: scale(1); opacity: 1 }
      }
      @keyframes amountAnim2 {
        from { top: -30px; opacity: 1 }
        to { top: 0; opacity: 0 }
      }
      @keyframes amountAnim3 {
        0% { top: -21px; opacity: 1;; transform: scale(0) }
        50% { top: -21px; opacity: 1;transform: scale(1.3) }
        100% { top: -21px; opacity: 1;transform: scale(1) }
      }
      @keyframes amountAnim4 {
        from { top: -21px; opacity: 1; }
        to { top: -51px; opacity: 0; }
      }
      @keyframes buttonAnim1 {
        0% { transform: translateY(0) }
        30% { transform: translateY(5px) }
        60% { transform: translateY(-2px) }
        100% { transform: translateY(0) }
      }
      @keyframes buttonAnim2 {
        0% { transform: translateY(0) }
        100% { transform: translateY(10px) }
      }
      @keyframes buttonAnim3 {
        0% { transform: translateY(10px) }
        50% { transform: translateY(-5px) }
        100% { transform: translateY(0) }
      }
      .export-amount-anim {
        position: absolute;
        right: @containerGap;
        top: -30px;
        width: 26px;
        height: 26px;
        line-height: 25px;
        //.display-flex();
        text-align: center;
        font-size: 10px;
        background-color: @adornColor;
        border-radius: 50%;
        border: 1px solid @adornColor;
        font-weight: 600;
        opacity: 0;
        box-shadow: 2px 2px 4px 0 rgba(248,83,0,0.2);
      }
      .export-amount-anim {
        // 加入的动画
        &.export-amount-anim-1 {
          background-color: @adornColor;
          color: @textColorFlip;
          animation: @transitionTime25 amountAnim1 forwards, @transitionTime15 amountAnim2 forwards;
          animation-delay: 0s, 0.5s;
        }
        // 减少的动画
        &.export-amount-anim-2 {
          background-color: @backColorStrong;
          color: @adornColor;
          animation: @transitionTime25 amountAnim3 forwards, @transitionTime15 amountAnim4 forwards;
          animation-delay: 0s, 0.5s;
        }
      }
      // 按钮-加入的动画
      .btn-start-export-anim-1 {
        animation: @transitionTime25 buttonAnim1 forwards;
        animation-delay: 0.65s;
      }
      // 按钮-减少的动画
      .btn-start-export-anim-2 {
        animation: @transitionTime25 buttonAnim2 forwards, @transitionTime25 buttonAnim3 forwards;
        animation-delay: 0s, 0.5s;
      }
      .export-hover-show {
        position: absolute;
        top: 100%;
        right: 0;
        width: 0;
        height: 0;
        opacity: 0;
        background-color: @backColorLight;
        z-index: 1;
        box-shadow: @shadowMid;
        white-space: nowrap;
        overflow: hidden;
        transition: all @transitionTime15;
        &.hover-show-import {
          opacity: 1;
          right: 0;
          width: auto;
          height: auto;
          transform: translateY(10px);
        }
        .export-hover-show-tit {
          padding: @containerGap @containerGap 0;
          .display-flex();
          justify-content: flex-start;
          font-weight: 600;
          .ivu-icon {
            margin-right: 3px;
            color: @themeColor;
          }
        }
        .export-hover-show-desc {
          padding: 0 @containerGap @containerGap;
          margin-top: 5px;
          color: @textColorLight;
          span {
            color: @adornColor;
            font-weight: 600;
          }
        }
    }
    }
  }
}
</style>

<template>
  <div class="operate-block-choose">
    <!-- 查询条件 -->
    <div>
      <yg-search-area
        :show-info="false"
        :value="searchOption"
        @on-search="_clickSearch"
      />
    </div>
    <div class="block-setting-area">
      <div
        class="setting-left"
      >
        小区总数: <span class="text-is-strong">{{ isSearchResult ? totalDataAmount.communityCount : '-' }}</span>个，
        充电桩总数: <span class="text-is-strong">{{ isSearchResult ? totalDataAmount.deviceCount : '-' }}</span>个
      </div>
      <div class="setting-right">
        <yg-button
          type="adorn-fill"
          @click="_clickIssue"
        >
          下发模板
        </yg-button>
        <!-- 导出数量的提示-->
        <div
          v-if="(page.total)"
          class="export-hover-show"
          :class="showHoverTip ? 'hover-show-import' : ''"
        >
          <div class="export-hover-show-tit">
            <Icon type="md-help-circle" />
            下发小助手
          </div>
          <div class="export-hover-show-desc">
            <div v-if="exportAmount">
              <p>1、当前<span>已勾选</span>小区；</p>
              <p>2、点击后下发已勾选的<span>{{ settingExportAmount.c }}个</span>小区,<span>{{ settingExportAmount.d }}个</span>设备；</p>
            </div>
            <div v-else>
              <p>1、当前<span>暂未勾选</span>任何小区；</p>
              <p>2、点击后下发<span>全部{{ totalDataAmount.communityCount }}个</span>小区,<span>{{ totalDataAmount.deviceCount }}个</span>设备；</p>
            </div>
          </div>
        </div>
        <!--勾选项变化时的动效-->
        <div
          v-if="(page.total)"
          class="export-amount-anim"
          :class="'export-amount-anim-' + exportAmountAnimationType"
        >
          {{ exportAmountGap }}
        </div>
      </div>
    </div>
    <div
      v-permission="'system:community:query'"
      class="padding-primary-top"
    >
      <yg-table
        :columns="tableColumns"
        :data="tableData"
        :page="page"
        @on-page-change="onChangePage"
        @on-selection="onChangeSelection"
      />
    </div>
  </div>
</template>

<script>
import OperationsApiSet from '@/request/api/OperationsApiSet';
import { TemplateBlockListModel } from '@/pages/108__operationsManager/billingTemplate/model/TemplateBlockListModel';
import { safeToFloat } from '@/libs/safe';

export default {
  components: {},
  props: {
    /**
     * 下发类型
     * 1：计费模板下饭
     * 2：分账模板下发
     * 3：运营模板下发
     */
    issueType: {
      type: Number,
      default: 1
    },
    templateId: {
      type: [Number, String],
      default: ''
    }
  },
  data () {
    return {
      searchOption: {
        region: BaseSearchModel.initData('省/市/区', 'region', 'YgRegionCascader').setData(2).setExclusions(['regionCompany', 'officeCode']),
        officeCode: BaseSearchModel.initData('吾行办事处', 'officeCode', 'YgGlobalSelect').setData('officeList').setExclusions(['regionCompany', 'region']),
        regionCompany: BaseSearchModel.initData('物业区域公司', 'regionCompany', 'YgGlobalSelect').setData('regionalCompanyList').setExclusions(['officeCode', 'region']),
        communityName: BaseSearchModel.initData('小区名称', 'communityName', 'Input')
      },
      settingItems: [
        BaseSettingModel.initData('导出列表', 'export', 'md-cloud-download').setExport().setExportReportType(23).setPermission('')
      ],
      tableColumns: [
        BaseTableColumn.initData('', '123').setSelection().setWidth(32).setAlign('center'),
        BaseTableColumn.initData('index'),
        BaseTableColumn.initData('name', '小区名称', 160).setSpan({ canTooltip: true }),
        BaseTableColumn.initData('area', '省市区').setKeyValueList([
          { label: '省份:', labelWidth: 50, key: 'provinceName', attr: { canTooltip: true } },
          { label: '城市:', labelWidth: 50, key: 'cityName', attr: { canTooltip: true } },
          { label: '区县:', labelWidth: 50, key: 'districtName', attr: { canTooltip: true } }
        ]).setWidth(120),
        BaseTableColumn.initData('deviceCount', '充电桩\n总数').setWidth(70).setSpan({}),
        BaseTableColumn.initData('address', '具体地址').setWidth(160).setSpan({ canTooltip: true }),
        BaseTableColumn.initData('officeName', '吾行\n办事处', 80).setSpan({ canTooltip: true }),
        BaseTableColumn.initData('orgName', '物业\n区域公司', 80).setSpan({ canTooltip: true })
      ],
      page: { current: 1, total: 0 },
      tableData: [],
      totalDataAmount: {
        communityCount: 0,
        deviceCount: 0
      },
      // 勾选中的id
      settingExportIdList: [],
      // 筛选条件
      settingExportAmount: {
        c: 0, // 小区数
        d: 0 // 设备数
      },
      /**
       * 0: 无动画
       * 1：加入
       * 2：减少
       */
      exportAmountAnimationType: 0,
      // 数量变化差值
      exportAmountGap: '',
      showHoverTip: false,
      timer: null,
      timer1: null,
      isSearchResult: false
    };
  },
  computed: {
    exportAmount () {
      return this.settingExportIdList.length;
    }
  },
  watch: {
    exportAmount (n, o) {
      this.clearTimer();
      this.exportAmountAnimationType = 0;
      this.exportAmountGap = n - o;
      if (this.exportAmountGap > 0) {
        this.exportAmountGap = '+' + this.exportAmountGap;
      }
      if (o > n) {
        if (n === 0) {
          this.setHoverShowTip();
        }
        this.exportAmountAnimStart(2);
      } else if (n > o) {
        if (o === 0) {
          this.setHoverShowTip();
        }
        this.exportAmountAnimStart(1);
      }
    }
  },
  destroyed () {
    this.clearTimer();
    this.clearTimer1();
  },
  methods: {
    /** ******************* 工具方法开始 ******************* **/
    clearTimer () {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = null;
    },
    clearTimer1 () {
      if (this.timer1) {
        clearTimeout(this.timer1);
      }
      this.timer1 = null;
    },
    setHoverShowTip () {
      this.clearTimer1();
      this.showHoverTip = true;
      this.timer1 = setTimeout(() => { this.showHoverTip = false; }, 2000);
    },
    exportAmountAnimStart (type) {
      this.exportAmountAnimationType = type;
      this.timer = setTimeout(() => {
        this.exportAmountAnimationType = 0;
      }, 900);
      const limit = this.$config.exportLimit;
      const warningLimit = limit - parseInt(this.$config.pageSize);
      if (this.exportAmount > limit && type === 1) {
        this.$Modal.warning({
          title: '勾选下发温馨提示',
          content: `最多支持：勾选${limit}个小区，</br>当前已勾选：${this.exportAmount}个，</br>较多小区时建议您使用全量下发。`,
          okText: '我知道了'
        });
      } else if (this.exportAmount > warningLimit) {
        this.$Message.info(`勾选下发，最多支持${limit}个小区，当前已勾选：${this.exportAmount}个`);
      }
    },
    /** ******************* 工具方法结束 ******************* **/

    /** ******************* 回调事件开始 ******************* **/
    onChangePage (page) {
      this.page.current = page;
      this.requestData();
    },
    onChangeSelection (list) {
      // 1.先把本页的数据清掉
      let selectList = this.settingExportIdList.filter(v => this.tableData.findIndex(v1 => v1._checkId === v._checkId) === -1);
      if (list.length > 0) {
        // 本页有勾选项放进去
        selectList = [...selectList, ...list];
      }
      this.settingExportIdList = selectList;
      let amount = 0;
      selectList.forEach(v => {
        amount += safeToFloat(v.deviceCount);
      });
      this.settingExportAmount.c = this.settingExportIdList.length;
      this.settingExportAmount.d = amount;
    },
    // 新增数据
    onSureBaseInfo () {
      const res = this.dialog.baseInfoModel.checkValidAdditionSubmit();
      if (!res.success) {
        this.$Message.warning(res.msg);
      } else {
        this.requestAdd(res.params);
      }
    },
    onCloseSubmit (needRefresh) {
      if (needRefresh) {
        this.onChangePage(1);
      }
      this.dialog.settingModel = null;
    },
    /** ******************* 回调事件结束 ******************* **/

    /** ******************* 点击事件开始 ******************* **/
    _clickSearch (option) {
      this.searchOption = option;
      this.page.current = 1;
      this.requestData();
    },
    _clickSetting (key) {
      console.log(key);
    },
    _clickIssue () {
      if (this.page.total === 0) {
        return this.$Modal.warning({
          title: '无数据可下发',
          content: '请先查询到结果后再进行下发',
          okText: '我知道了'
        });
      }
      const style = '"color: #f85300;font-size: 22px;font-weight: 600"';
      const c = this.exportAmount ? this.settingExportAmount.c : this.totalDataAmount.communityCount;
      const d = this.exportAmount ? this.settingExportAmount.d : this.totalDataAmount.deviceCount;
      let str = '';
      if (this.issueType === 1) {
        str = '计费';
      } else if (this.issueType === 2) {
        str = '分账';
      } else {
        str = '运营';
      }
      this.$Modal.confirm({
        title: '操作提示',
        content: `
          <p>是否确认对<span style=${style}>${c}</span>个小区，共<span style=${style}>${d}</span>台设</p>
          <p>下发${str}规则？</p>
        `,
        onOk: () => {
          setTimeout(() => {
            this.requestIssue();
          }, 500);
        }
      });
    },
    /** ******************* 点击事件结束 ******************* **/

    /** ******************* 数据请求开始 ******************* **/
    requestData () {
      this.tableData = [];
      this.page.total = 0;
      this.isSearchResult = false;
      this.totalDataAmount.communityCount = 0;
      this.totalDataAmount.deviceCount = 0;
      const api = OperationsApiSet.communityList;
      const s = this.searchOption;
      api.params = {
        pageSize: this.$config.pageSize,
        pageNo: this.page.current,
        orgCode: s.regionCompany.value,
        province: s.region.value[0] || '',
        city: s.region.value[1] || '',
        district: s.region.value[2] || '',
        communityName: s.communityName.value,
        officeCode: s.officeCode.value
      };
      this.settingExportCondition = api.params;
      this.$http(api).then(res => {
        const resData = res.data;
        this.page.total = resData.total;
        this.totalDataAmount.communityCount = resData.communityCount;
        this.totalDataAmount.deviceCount = resData.deviceCount;
        this.tableData = TemplateBlockListModel.initListWithDataList(resData.communityList);
        TemplateBlockListModel.checkModelIsSelect(this.tableData, this.settingExportIdList);
        this.isSearchResult = true;
      });
    },
    requestIssue () {
      let api = null;
      if (this.issueType === 1) {
        api = OperationsApiSet.issueComputeFeeTemplate;
      } else if (this.issueType === 2) {
        api = OperationsApiSet.issueAllocateTemplate;
      } else {
        api = OperationsApiSet.issueOperationConfigTemplate;
      }
      const list = [];
      this.settingExportIdList.forEach(v => {
        list.push(v._checkId);
      });
      api.params = {
        templateId: this.templateId
      };
      if (list.length) {
        api.params = {
          templateId: this.templateId,
          communityIds: list
        };
      } else {
        const s = this.searchOption;
        api.params = {
          templateId: this.templateId,
          orgCode: s.regionCompany.value,
          province: s.region.value[0] || '',
          city: s.region.value[1] || '',
          district: s.region.value[2] || '',
          communityName: s.communityName.value,
          officeCode: s.officeCode.value
        };
      }
      this.$http(api).then(res => {
        this.settingExportIdList = [];
        this.settingExportAmount = { c: 0, d: 0 };
        this.$Modal.confirm({
          title: '温馨提示',
          content: '操作成功，请稍后在下发记录查看详细情况',
          okText: '查看详情',
          cancelText: '关闭窗口',
          onOk: () => {
            this.$emit('on-finish', true);
          },
          onCancel: () => {
            this.$emit('on-finish', false);
          }
        });
      });
    }
    /** ******************* 数据请求结束 ******************* **/
  }
};
</script>
