/**
 * Created by 程雨喵'mac on 2024/5/10.
 * Copyright © 2024年 云柜-金星晖. All rights reserved.
 */

export const safeToFloat = (num, len = 2) => {
  const n = Number(num);
  if (!Number.isNaN(n)) {
    return parseFloat(n.toFixed(len));
  }
  return 0;
};
