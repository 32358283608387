/**
 * @date:2024/08/29
 * @author 王丽莎
 * @copyright 南京云柜<yun-gui.com>
 * 运营管理相关请求
 */

import BaseApi from '@/request/base/BaseApi';

class OperationsApiSet {
  constructor () {
    this.communityList = BaseApi.createModel('下发范围(小区列表)', '/opmp/admin/template/community/list', true).setMethod(1);
    // 计费模板 ---------------- start >
    this.getComputeFeeList = BaseApi.createModel('计费模板列表', '/opmp/admin/template/getComputeFeeList', true).setMethod(1);
    this.addComputeFee = BaseApi.createModel('计费模板新增', '/opmp/admin/template/addComputeFee', true).setMethod(1);
    this.getComputeFee = BaseApi.createModel('计费模板详情', '/opmp/admin/template/getComputeFee', true).setMethod(1);
    this.deleteComputeFee = BaseApi.createModel('计费模板删除', '/opmp/admin/template/deleteComputeFee', true).setMethod(1);
    this.issueComputeFeeTemplate = BaseApi.createModel('计费模板下发', '/opmp/admin/template/issueComputeFeeTemplate', true).setMethod(1);
    this.getComputeFeeIssueList = BaseApi.createModel('计费模板下发列表', '/opmp/admin/template/getComputeFeeIssueList', true).setMethod(1);
    // 计费模板 ---------------- end >
    // 分账模板 ---------------- start >
    this.qryAllocateTemplateList = BaseApi.createModel('分账模板列表', '/opmp/admin/template/qryAllocateTemplateList', true).setMethod(1);
    this.addAllocateTemplate = BaseApi.createModel('分账模板新增', '/opmp/admin/template/addAllocateTemplate', true).setMethod(1);
    this.deleteAllocateTemplate = BaseApi.createModel('分账模板删除', '/opmp/admin/template/deleteAllocateTemplate', true).setMethod(1);
    this.qryAllocateTemplateDetail = BaseApi.createModel('分账模板详情', '/opmp/admin/template/qryAllocateTemplateDetail', true).setMethod(1);
    this.issueAllocateTemplate = BaseApi.createModel('分账模板下发', '/opmp/admin/template/issueAllocateTemplate', true).setMethod(1);
    this.issueAllocateRecord = BaseApi.createModel('分账模板下发记录', '/opmp/admin/template/issueAllocateRecord', true).setMethod(1);
    // 分账模板 ---------------- end >
    // 运营模板 ---------------- start >
    this.getOperationConfigList = BaseApi.createModel('运营模板列表', '/opmp/admin/template/getOperationConfigList', true).setMethod(1);
    this.addOperationConfig = BaseApi.createModel('运营模板新增', '/opmp/admin/template/addOperationConfig', true).setMethod(1);
    this.deleteOperationConfig = BaseApi.createModel('运营模板删除', '/opmp/admin/template/deleteOperationConfig', true).setMethod(1);
    this.getOperationConfig = BaseApi.createModel('运营模板详情', '/opmp/admin/template/getOperationConfig', true).setMethod(1);
    this.issueOperationConfigTemplate = BaseApi.createModel('运营模板下发', '/opmp/admin/template/issueOperationConfigTemplate', true).setMethod(1);
    this.getOperationConfigIssueList = BaseApi.createModel('运营模板下发记录', '/opmp/admin/template/getOperationConfigIssueList', true).setMethod(1);
    // 运营模板 ---------------- end >
  }
}

export default new OperationsApiSet();
